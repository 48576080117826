import React from 'react';
import { Link } from 'react-router-dom';
import logo from "./logo.png";


const NavBar = () => {
  return (
    <nav className='flex bg-[#001f3f] text-white py-2  w-full'>
      <div className='text-center w-full sm:w-[30%] flex'>
        <img className='w-[100px] h-[50px] px-5 ml-5 ' src={logo} alt="logo" />
    <p className='text-xl font-semibold whitespace-nowrap mt-3 -ml-2 '>Website SEO Checker</p>
    </div>
    <div className='hidden sm:block ml-[50%] w-0 sm:w-[70%]'>
    <ul className='flex text-xl  gap-6 whitespace-nowrap mt-2'>
      <li><Link to="/">Home</Link></li>
      <li>Blog</li>
      <li><Link to="/privacy-policy">Privacy Policy</Link></li>
      <li><Link to="/about-us">About Us</Link></li>
    </ul>
    </div>
    
    </nav>



  )
}

export default NavBar;