import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab); // Add the Font Awesome Brands icons to the library



const Footer = () => {
  return (
    <footer className='flex flex-col sm:flex-row bg-[#001f3f] text-white py-3  h-[400px] sm:h-[43px] w-full '>
    <div className='text-center sm:ml-10 w-full sm:w-[30%] flex flex-col sm:flex-row justify-center items-center'>
      <img className='w-[100px] h-[50px] mt-1' src={logo} alt="logo" />
  <p className='text-xl font-semibold whitespace-nowrap mt-3 sm:mt-1  px-5 '>Website SEO Checker</p>
  </div>

  <ul className='flex flex-col sm:flex-row text-xl text-center  gap-6 whitespace-nowrap mt-10 sm:mt-0'>
    <li><Link to="/">Home</Link> </li>
    <li> <Link to="/privacy-policy">Privacy Policy</Link> </li>
    <li><Link to="/contact">Contact</Link></li>
    <li><Link to="/about-us">About Us</Link> </li>
  </ul>
  
  <div className='sm:ml-[20%]'>
    <ul className='flex flex-row sm:flex-row gap-x-4 justify-center mt-6 sm:mt-0 '>
     <li>
     <a href="/" target="_blank" rel="noopener noreferrer">
        <FaFacebook size={30} style={{ marginRight: '10px' }} />
      </a>
     </li>

     <li>
     <a href="/" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={30} style={{ marginRight: '10px' }} />
      </a>
     </li>

     <li>
     <a href="/" target="_blank" rel="noopener noreferrer">
        <FaTwitter size={30} />
      </a>
     </li>
    </ul>
  </div>
  
  </footer>
  )
}

export default Footer;