import React from 'react';
import report from './report.png';
import issues from './issues.png';
import recommendation from './recommendation.png';
import { Link } from 'react-router-dom';

const Main = () => {
  return (
   <main className='flex flex-col sm:flex-row'>
    
    <div className='w-[100%] sm:w-[70%]'>
    <h1 className='text-center text-3xl font-semibold mt-10 mb-5'>Unlock the advantages provided by SEO Analyzer.</h1>
    <section className='mt-2 w-full flex flex-col sm:flex-row mb-10 gap-y-10'>
    <div className='flex flex-col justify-center items-center  w-full sm:w-[32%] '>
        <img className='' src={report} alt="report" />
        <p className='mt-2 text-center w-[80%] text-xl'>Explore a comprehensive analysis of your website. Identify strengths and areas for enhancement in your web pages.</p>
    </div>
    <div className='flex flex-col justify-center items-center w-full sm:w-[33%]'>
        <img className='' src={issues} alt="issues" />
        <p className='mt-2 text-center w-[80%] text-xl'>Effortlessly pinpoint SEO-related issues on your website and receive practical insights for proactive resolution.</p>

    </div>
    <div className='flex flex-col justify-center items-center w-full sm:w-[33%]'>
        <img src={recommendation} alt="recommendation" />
        <p className='mt-2 text-center w-[80%] text-xl'>Leverage our recommendations to effortlessly address SEO issues, boosting your website's ranking with ease.</p>
    </div>
    </section>
    </div>

    <div className='w-[95%] sm:w-[30%] mt-10 sm:mr-5 border-[2px] border-black h-[30%]  rounded mb-5 sm:mb-0 '>
      <div className=''>
        <h2 className='bg-[#001f3f] text-white text-center text-xl font-semibold'>More Tools</h2>
      </div>
      <ul className='text-xl font-semibold'>
        <Link to="/">
        <li className='bg-slate-300 px-2 py-2'>SEO Extractor</li>
        </Link>
        <Link to="/page-authority-checker">
        <li className='px-2 py-2'>Page Authority Checker</li>
        </Link>
        <Link to="/keyword-suggest">
        <li className='bg-slate-300 px-2 py-2'>Keyword Suggestion</li>
        </Link>
        <Link to="/backlinks-checker">
        <li className='px-2 py-2'>Backlinks Checker</li>
        </Link>
      </ul>
    </div>
    </main>
    
  )
}

export default Main