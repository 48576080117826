import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SeoChecker from './components/SeoChecker';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import DaPaChecker from './components/DaPaChecker';
import DomainAgeChecker from './components/DomainAgeChecker';
import Policy from './components/Policy';
import About from './components/About';
import Contact from './components/Contact';
import Main from './components/Main';
import BacklinksChecker from './components/BacklinksChecker';
import KeywordAutoSuggest from './components/KeywordSuggest';
import Paragraph from './components/Paragraph';

function App() {
  const showMainComponent = (pathname) => {
    // Specify the paths where you want to show the Main component
    const allowedPaths = ['/', '/da-pa-checker', '/domain-age-checker', '/contact', '/backlinks-checker', '/keyword-suggest'];
    return allowedPaths.includes(pathname);
  };

  const showParagraph = (pathname) => {
    const allowedPaths = ['/', '/page-authority-checker', '/keyword-suggest', '/contact', '/backlinks-checker',];
    return allowedPaths.includes(pathname);
  }

  return (
    <Router>
      <div className='overflow-hidden'>
        <NavBar />
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <SeoChecker />
                {showMainComponent('/') && <Main /> }
                {showParagraph('/') && <Paragraph />}
              </div>
            }
          />
          <Route
            path="/page-authority-checker"
            element={
              <div>
                <DaPaChecker />
                {showMainComponent('/da-pa-checker') && <Main />}
                {showParagraph('/page-authority-checker') && <Paragraph />}
              </div>
            }
          />
          <Route
            path="/domain-age-checker"
            element={
              <div>
                <DomainAgeChecker />
                {showMainComponent('/domain-age-checker') && <Main />}
              
              </div>
            }
          />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={
              <div>
                <Contact />
                {showMainComponent('/contact') && <Main />}
              </div>
            } />

<Route
            path="/backlinks-checker"
            element={
              <div>
                <BacklinksChecker />
                {showMainComponent('/backlinks-checker') && <Main />}
                {showParagraph('/backlinks-checker') && <Paragraph />}
              </div>
            }
          />

<Route
            path="/keyword-suggest"
            element={
              <div>
                <KeywordAutoSuggest />
                {showMainComponent('/keyword-suggest') && <Main />}
                {showParagraph('/keyword-suggest') && <Paragraph />}
              </div>
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
