"use client";
import React, { useState } from 'react';
import axios from 'axios';
import "./loader.css";
import { Helmet } from 'react-helmet';

const SEOChecker = () => {
  const [urlInput, setUrlInput] = useState('');
  const [seoData, setSeoData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  


  const extractSEO = async () => {
    if (!urlInput) {
      setError('Please enter a valid URL.');
      return;
    }

    const encodedParams = new URLSearchParams();
    encodedParams.set('url', urlInput);

    const options = {
      method: 'POST',
      url: 'https://canssens-seo-extraction-v1.p.rapidapi.com/seo/api/',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-RapidAPI-Key': 'cca330428dmsh4b459b029c77e3cp1a7504jsn8f61efbba564',
        'X-RapidAPI-Host': 'canssens-seo-extraction-v1.p.rapidapi.com',
      },
      data: encodedParams,
    };

    try {
      setIsLoading(true); 
      const response = await axios.request(options);
      setSeoData(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setSeoData(null);
      setError('Error during SEO extraction. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      extractSEO();
    }
  };

  return (
    
    <div>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
   <Helmet>
        <title>SEO Extractor</title>
        <meta name="description" content='Enhance rankings, analyze authority. SEO Extractor – your tool for optimal website performance and visibility.' />
        <meta name="keywords" content="Website-SEO-Checker, Website SEO Checker, Website SEO, Website SEO Analysis, Website SEO Report, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO Tool, Website SEO Analysis Tool, Website SEO Report Tool, Website SEO" />
      </Helmet>
      <div className='bg-[#003366] text-white w-[100%]  border-[2px] border-[#34495e] p-5'>
        <h1 className='text-center text-3xl font-semibold'>SEO Extractor</h1>
        <p className='text-xl mt-4 text-center'>Enhance rankings, analyze authority. SEO Extractor – your tool for optimal website performance and visibility.</p>
        <div className='flex flex-col text-center'>
          <input
            className='border-[#34495e] text-black border-[2px] w-[90%] sm:w-[55%] ml-[5%] sm:ml-[22%] mt-5 py-1 rounded-lg px-2'
            placeholder='Enter your URL'
            type="text"
            value={urlInput}
            onChange={(e) => setUrlInput(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <div className='mt-4'>
            <button onClick={extractSEO} className='bg-blue-600 text-white px-4 py-2 rounded'>
              Extract
            </button>
          </div>
        </div>
      </div>

      <div>
        {error && <p style={{ color: 'red' }}>{error}</p>}

        {seoData && (
          <div className='mt-4 px-6'>
            <div>
              <div class="relative overflow-x-auto rounded-xl">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-center text-xl">
                        SEO Extract
                      </th>
                    </tr>
                  </thead>
                  <tbody>
            <tr class="flex flex-col sm:flex-row  bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    URL:
                </th>
                <td class="px-6 py-4">
                {seoData.url}
                </td>
                
            </tr>
            <tr class="flex flex-col sm:flex-row bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   Title:
                </th>
                <td class="px-6 py-4">
                   {seoData.title}
                </td>
            
            </tr>
            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   Keywords:
                </th>
                <td class="px-6 py-4">
                    {seoData.keywords}
                </td>
                
                
            </tr>

            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   Description:
                </th>
                <td class="px-6 py-4">
                    {seoData.description}
                </td>
                
                
            </tr>

            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   H1 Tags:
                </th>
                <td class="px-6 py-4">
                {seoData.h1 && seoData.h1.map((item, index) => (
            <React.Fragment key={index}>
                {item}
                {index < seoData.h1.length - 1 && ' , '}
            </React.Fragment>
        ))}
                </td>
                
                
            </tr>

            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   H2 Tags:
                </th>
                <td class="px-6 py-4">
                {seoData.h2 && seoData.h2.map((item, index) => (
            <React.Fragment key={index}>
                {item}
                {index < seoData.h1.length - 1 && ' , '}
            </React.Fragment>
        ))}
                </td>
                
                
            </tr>

            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   H3 Tags:
                </th>
                <td class="px-6 py-4">
                {seoData.h3 && seoData.h3.map((item, index) => (
            <React.Fragment key={index}>
                {item}
                {index < seoData.h1.length - 1 && ' , '}
            </React.Fragment>
        ))}
                </td>
                
                
            </tr>


            
            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   Links:
                </th>
                <td class="px-6 py-4">
                {seoData.links && seoData.links.map((link, index) => (
            <React.Fragment key={index}>
                {link}
                {index < seoData.links.length - 1 && ','}
                <br />
            </React.Fragment>
        ))}
                </td>
                
                
            </tr>
        </tbody>
    </table>
</div>

        </div>

        </div>
      )}
      </div>
    </div>
  );
};

export default SEOChecker;