'use client';
import React, { useState } from 'react';
import axios from 'axios';
import "./loader.css";
import { Helmet } from 'react-helmet';

const KeywordAutoSuggest = () => {
  const [keywordInput, setKeywordInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  
 
  const fetchSuggestions = async () => {
    if (!keywordInput) {
      setError('Please enter a keyword.');
      return;
    }
  
    const options = {
      method: 'GET',
      url: 'https://keyword-autosuggest.p.rapidapi.com/autosuggest',
      params: { q: keywordInput },
      headers: {
        'X-RapidAPI-Key': 'cca330428dmsh4b459b029c77e3cp1a7504jsn8f61efbba564',
        'X-RapidAPI-Host': 'keyword-autosuggest.p.rapidapi.com',
      },
    };
  
    try {
      setIsLoading(true); 
      const response = await axios.request(options);
      console.log(response); // Log the entire response for debugging
  
      // Check if the response contains the expected data structure
      if (response.data && response.data.success && Array.isArray(response.data.result)) {
        setSuggestions(response.data.result);
        setError(null);
      } else {
        setSuggestions([]);
        setError('Invalid data structure in the API response.');
      }
    } catch (error) {
      console.error(error);
      setSuggestions([]);
      setError('Error fetching keyword suggestions. Please try again later.');
    } finally {
      setIsLoading(false); 
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        fetchSuggestions();
    }
  };

  return (

<div>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
  <Helmet>
    <title>Keyword Suggesstion Tool</title>
    <meta name="description" content="Boost SEO effortlessly with our dynamic keyword suggestion tool for enhanced website visibility and performance." />
    <meta name="description" content="keyword suggesstion tool, free keyword suggesstion tool, online keyword suggestion, keyword suggesst, long tail keyword suggesstion tool, keywords suggesstion," />
  </Helmet>
      <div className='bg-[#003366] text-white w-[100%]  border-[2px] border-[#34495e] p-5'>
        <h1 className='text-center text-3xl font-semibold'>Keyword Suggestion</h1>
        <p className='text-xl mt-4 text-center'>Boost SEO effortlessly with our dynamic keyword suggestion tool for enhanced website visibility and performance.</p>
        <div className='flex flex-col text-center'>
          <input
            className='border-[#34495e] text-black border-[2px] w-[90%] sm:w-[55%] ml-[5%] sm:ml-[22%] mt-5 py-1 rounded-lg px-2'
            placeholder='Enter your keyword'
            type="text"
            value={keywordInput}
            onChange={(e) => setKeywordInput(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <div className='mt-4'>
            <button onClick={fetchSuggestions} className='bg-blue-600 text-white px-4 py-2 rounded'>
              Submit
            </button>
          </div>
        </div>
      </div>

      {error && <p style={{ color: 'red' }}>{error}</p>}
      {suggestions.length > 0 && (
        <div class='flex  items-center justify-center '>
          <div class="flex items-center justify-center min-h-[450px]">
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="py-3 px-6 ">Keywords</th>
                      <th scope="col" class="py-3 px-6"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td class="py-4 px-6">{suggestions.map((_, index) => (
                        <div key={index}>{index + 1}.</div>
                      ))}</td>
                      <td class="py-4 px-6">{suggestions.map((keyword, index) => (
                        <div key={index}>{keyword}</div>
                      ))}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default KeywordAutoSuggest;